import { IncomingMessage } from "http"
import { Middleware, SWRHook } from "swr"
import { ICookieSetter, IGetFPData } from "../../types"
import cookies from "../cookies"
import { getRuntimeConfig, IPublicRuntimeConfig } from "../runtimeConfig"
import { getSessionIDBrowser, getSessionIDServer } from "../session"
import {
  DID_COOKIE_KEY,
  getPageUrlAndReferrer,
  getPageUrlAndReferrerServer,
  SESSION_ID_COOKIE_KEY,
} from "../session/session"
import { getStorageTrackingItems } from "../tracking"
import { isServer } from "../utils"
import { getTrackingKeysList } from "./utils"
import { NextRequest } from "next/server"

const xTrackingHeaderName = "X-Tracking"

export const getXTrackingHeaderServer = async (
  getRuntimeConfig: () => IPublicRuntimeConfig,
  setter: ICookieSetter,
  request: NextRequest
) => {
  const [
    landingUrlFromReq,
    LandingReferrerFromReq,
  ] = getPageUrlAndReferrerServer(request)

  const requestData = {
    url: landingUrlFromReq,
    refferer: LandingReferrerFromReq,
  }

  const cookieData = {
    sessionCookieParsed:
      request.cookies.get(SESSION_ID_COOKIE_KEY)?.value.split("|") || [],
    uuid: request.cookies.get(DID_COOKIE_KEY)?.value,
  }
  const { sessionId, landingUrl, landingReferrer } = getSessionIDServer(
    {
      setter,
      requestData,
      cookieData,
    },
    getRuntimeConfig
  )

  const { ip } = request

  const data: Record<string, any> = {
    tracking_data: {
      sessionData: sessionId,
      api_client: "web",
      trackingParameters: {
        landing_url: landingUrl,
        landing_referrer: landingReferrer,
        current_url: landingUrlFromReq,
        current_referrer: LandingReferrerFromReq,
        user_agent: request.headers.get("user-agent"),
        cookie_storage: "",
        ip,
        local_storage: {},
        session_storage: {},
      },
    },
  }

  try {
    const cookieStorage: { [p: string]: string } = {}
    const cookiesArray = request.cookies.getAll()
    cookiesArray.forEach((k) => (cookieStorage[k.name] = k.value))

    data.tracking_data.trackingParameters.cookie_storage = getStorageTrackingItems(
      getTrackingKeysList("cookie_storage"),
      (k: string) => cookieStorage[k]
    )
  } catch (e) {
    data.tracking_data.trackingParameters.cookie_storage = ""
  }

  const base64Value = btoa(JSON.stringify(data))

  return {
    [xTrackingHeaderName]: base64Value,
  }
}

export const getXTrackingHeader = async (
  getRuntimeConfig: () => IPublicRuntimeConfig,
  getFPData?: IGetFPData,
  setter?: ICookieSetter,
  currentCookie?: string,
  req?: IncomingMessage
): Promise<Record<typeof xTrackingHeaderName, string>> => {
  const { FINGERPRINTS_IS_ENABLED } = getRuntimeConfig()
  const [currentUrl, currentRefferer] = getPageUrlAndReferrer(req)

  const cookieData = currentCookie
    ? {
        sessionCookieParsed:
          cookies.get(currentCookie, SESSION_ID_COOKIE_KEY)?.split("|") || [],
        uuid: cookies.get(currentCookie, DID_COOKIE_KEY),
      }
    : undefined

  const requestData = {
    url: currentUrl,
    refferer: currentRefferer,
  }

  const { sessionId, landingUrl, landingReferrer } =
    isServer() && setter
      ? getSessionIDServer(
          {
            setter,
            requestData,
            cookieData,
          },
          getRuntimeConfig
        )
      : getSessionIDBrowser(getRuntimeConfig)

  let ip

  try {
    if (isServer()) {
      ip = req?.socket.remoteAddress
    } else if (FINGERPRINTS_IS_ENABLED && getFPData) {
      ip = (await getFPData()).ip
    }
  } catch (error) {
    console.error(error)
  }

  const localStorageData = isServer()
    ? {}
    : getStorageTrackingItems(
        getTrackingKeysList("local_storage"),
        (k: string) => window.localStorage.getItem(k)
      )

  const sessionStorageData = isServer()
    ? {}
    : getStorageTrackingItems(
        getTrackingKeysList("session_storage"),
        (k: string) => window.sessionStorage.getItem(k)
      )
  const data: Record<string, any> = {
    tracking_data: {
      sessionData: sessionId,
      api_client: "web",
      trackingParameters: {
        landing_url: landingUrl,
        landing_referrer: landingReferrer,
        current_url: currentUrl,
        current_referrer: currentRefferer,
        user_agent: isServer()
          ? req?.headers["user-agent"]
          : window.navigator.userAgent,
        cookie_storage: "",
        ip,
        local_storage: localStorageData,
        session_storage: sessionStorageData,
      },
    },
  }

  const cookie = isServer()
    ? req?.headers?.cookie || ""
    : window.document.cookie

  try {
    const cookieStorage = cookies.get(cookie) || {}
    data.tracking_data.trackingParameters.cookie_storage = getStorageTrackingItems(
      getTrackingKeysList("cookie_storage"),
      (k: string) => cookieStorage[k]
    )
  } catch (e) {
    data.tracking_data.trackingParameters.cookie_storage = ""
  }

  const base64Value = btoa(JSON.stringify(data))

  return {
    [xTrackingHeaderName]: base64Value,
  }
}

const trackingMiddleware = ({
  getFPData,
}: {
  getFPData: IGetFPData
}): Middleware => (useSWRNext: SWRHook) => {
  return (key, fetcher, config) => {
    const swr = useSWRNext(
      key,
      fetcher
        ? async (arg0: any, arg1: any) => {
            const headers = await getXTrackingHeader(
              getRuntimeConfig,
              getFPData
            )

            if (arg1?.arg) {
              return fetcher(arg0, {
                arg: {
                  ...arg1.arg,
                },
                headers,
              })
            }

            if (typeof arg0 === "object") {
              const extendedArgs = {
                ...arg0,
                headers,
              }
              return fetcher(extendedArgs)
            }

            return fetcher(arg0, { headers })
          }
        : null,
      config
    )

    return swr
  }
}

export default trackingMiddleware
