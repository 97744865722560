import { ApolloLink, createHttpLink } from "@apollo/client"
import { onError } from "@apollo/client/link/error"

import { getRuntimeConfig } from "../runtimeConfig"
import { CustomHeader } from "../../types"
import { axiosFetch } from "../rest"
import { extendWithFatalErrors } from "./gqlError"

const runtimeConfig = getRuntimeConfig()

const errorAfterware = onError(({ graphQLErrors, response }) => {
  if (graphQLErrors && response) {
    response.errors = extendWithFatalErrors(graphQLErrors)
  }
})

export const contentfulLink = createHttpLink({
  fetch: axiosFetch,
  uri: `https://graphql.contentful.com/content/v1/spaces/${runtimeConfig.CONTENTFUL_SPACE_ID}/environments/${runtimeConfig.CONTENTFUL_ENVIRONMENT}`,
  credentials: "same-origin",
  headers: {
    [CustomHeader.AUTH]: `Bearer ${runtimeConfig.CONTENTFUL_ACCESS_TOKEN}`,
  },
})

const makeContentfulLink = (): ApolloLink =>
  ApolloLink.from([errorAfterware, contentfulLink])

export default makeContentfulLink
