"use client"

import React from "react"
import { AbstractIntlMessages, NextIntlProvider } from "next-intl"
import { FpjsProvider } from "@fingerprintjs/fingerprintjs-pro-react"

import {
  RuntimeConfigProvider,
  useRuntimeConfig,
} from "../../hooks/runtimeConfig"
import { ConsentProvider } from "../../hooks/consent"
import ConditionalWrap from "../../ui-lib/ConditionalWrap"
import TrackingProvider from "../../hooks/tracking/TrackingProvider.client"
import { ViewportProvider } from "../../hooks/viewport"
import { DomRefsProvider } from "../../hooks/domRefs"
import { UserProvider } from "../../hooks/user"
import { AuthenticationProvider } from "../../hooks/authentication"
import { protectedRoutes } from "../../types"
import MyFavoritesProvider from "../../hooks/myFavorites/MyFavoritesProvider"
import { ProductServiceProvider } from "../../hooks/productService"
import FreshChat from "../../ui-lib/FreshChat"
import SWRWrapper from "../../hooks/api/SWRWrapper"
import ThemeRegistry from "../ThemeRegistry/ThemeRegistry"
import { ApolloProvider } from "@apollo/client"
import { makeClient } from "../../utils/graphql/withApollo"
import PromotionsSnackbar from "../../ui-lib/Promotions/PromotionsSnackbar"
import { RateReminder, RateFlowModal } from "../../ui-lib/Rating"

const Providers: React.FC<{
  messages: AbstractIntlMessages
  token: string | undefined
  isQuestico: boolean
  children: React.ReactNode
}> = ({ children, messages, token, isQuestico }) => {
  const { getRuntimeConfig } = useRuntimeConfig()

  const {
    FINGERPRINTS_IS_ENABLED,
    FINGERPRINTJS_API_KEY,
    FINGERPTINTS_PROXY,
  } = getRuntimeConfig()
  return (
    <RuntimeConfigProvider>
      <ConsentProvider>
        <NextIntlProvider messages={messages} locale="de">
          <ConditionalWrap
            condition={FINGERPRINTS_IS_ENABLED}
            wrap={(children) => (
              <FpjsProvider
                loadOptions={{
                  apiKey: FINGERPRINTJS_API_KEY,
                  region: "eu",
                  endpoint: FINGERPTINTS_PROXY,
                  scriptUrlPattern: `${FINGERPTINTS_PROXY}/web/v<version>/<apiKey>/loader_v<loaderVersion>.js`,
                }}
              >
                {children}
              </FpjsProvider>
            )}
          >
            <ApolloProvider client={makeClient({})}>
              <SWRWrapper>
                <TrackingProvider>
                  <ViewportProvider>
                    <ThemeRegistry isQuestico={isQuestico}>
                      <DomRefsProvider>
                        <UserProvider user={undefined}>
                          <AuthenticationProvider
                            protectedPaths={protectedRoutes}
                            token={token}
                          >
                            <MyFavoritesProvider>
                              <ProductServiceProvider>
                                {children}

                                <FreshChat />

                                <PromotionsSnackbar />

                                <RateReminder />

                                <RateFlowModal />
                              </ProductServiceProvider>
                            </MyFavoritesProvider>
                          </AuthenticationProvider>
                        </UserProvider>
                      </DomRefsProvider>
                    </ThemeRegistry>
                  </ViewportProvider>
                </TrackingProvider>
              </SWRWrapper>
            </ApolloProvider>
          </ConditionalWrap>
        </NextIntlProvider>
      </ConsentProvider>
    </RuntimeConfigProvider>
  )
}

export default Providers
