import { ApolloClient } from "@apollo/client"
import { InMemoryCache } from "@apollo/client/cache"
import makeWithApollo, { InitApolloOptions } from "next-with-apollo"

import { Environment, TGraphQLClient } from "../../types"
import ApolloProviderRenderer from "./ApolloProviderRenderer"
import makeContentfulLink from "./apolloLinkContentful"
import { getRuntimeConfig } from "../runtimeConfig"

const { ENVIRONMENT } = getRuntimeConfig()

export function makeClient({
  initialState,
}: InitApolloOptions<any>): TGraphQLClient {
  const appCache = new InMemoryCache({
    typePolicies: {
      ExpertListing: {
        keyFields: ["listingNo"],
      },
    },
  }).restore(initialState)

  // See example:https://www.apollographql.com/docs/react/networking/authentication/
  // and https://www.apollographql.com/docs/react/networking/network-layer/
  const client = new ApolloClient({
    connectToDevTools: ENVIRONMENT !== Environment.PRODUCTION,

    // Disables forceFetch on the server (so queries are only run once)
    ssrMode: typeof window !== "undefined",

    link: makeContentfulLink(),

    // here we have cache for both clients initialised
    // hope they won't conflict or something
    cache: appCache,

    /**
     * according to https://www.apollographql.com/docs/react/data/local-state/#handling-client-fields-with-the-cache
     * If you want to use Apollo Client's @client support to query the cache without using local resolvers,
     * you must pass an empty object into the ApolloClient constructor resolvers option.
     * Without this Apollo Client will not enable its integrated @client support,
     * which means your @client based queries will be passed to the Apollo Client link chain.
     *
     * and since we use local state for `authStatus` and don't need to resolve it manually
     * we must have this resolvers object empty.
     *
     * @issue https://adviqo.atlassian.net/browse/MD-351
     * @note feel free to add resolvers if necessary, but make sure `authStatus` also works
     */
    resolvers: {},
  })

  return client
}

// Export a HOC from next-with-apollo
// Docs: https://www.npmjs.com/package/next-with-apollo
const withApollo = makeWithApollo(
  // You can get headers and ctx (context) from the callback params
  // e.g. ({ headers, ctx, initialState })
  makeClient,
  {
    render: ApolloProviderRenderer,
  }
)

export default withApollo
