"use client"

import React from "react"
import { NextPage } from "next"
import { ApolloProvider } from "@apollo/client"

interface IApolloProviderRenderer {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Page: NextPage<any>
  props: any
}

const ApolloProviderRenderer: React.FC<IApolloProviderRenderer> = ({
  Page,
  props,
}) => {
  const { apollo } = props

  return (
    <ApolloProvider client={apollo}>
      <Page {...props} />
    </ApolloProvider>
  )
}

export default ApolloProviderRenderer
