const isFatalGraphQlError = (error: any) => {
  return (
    error?.extensions?.contentful?.code === "UNRESOLVABLE_LINK" &&
    (error?.extensions?.contentful?.details?.field === "config" ||
      error?.extensions?.contentful?.details?.field === "filterBox")
  )
}

export const extendWithFatalErrors = (errors: unknown | any) => {
  const makeError = (error: unknown | any) => {
    if (!isFatalGraphQlError(error)) {
      return error
    }

    return {
      ...error,
      extensions: {
        ...error.extensions,
        adviqo: {
          code: "FATAL",
        },
      },
    }
  }

  if (Array.isArray(errors)) {
    return errors.map(makeError)
  }

  return makeError(errors)
}
